/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import DomainException from '~/src/Domain/Shared/Exception/DomainException'

export default class PageHasNoEngagementURLParametersException extends DomainException {
  public constructor(message: string) {
    super(message)
    this.name = 'PageHasNoEngagementURLParametersException'
  }
}
